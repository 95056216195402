<template>
    <div class="US_warrper">
        <p class="title">名称</p>
        <p class="subtitle">{{}}</p>
        <p class="title">地址</p>
        <p class="subtitle">深圳市福田区沙头街道天安社区泰然六路泰然苍松大厦六层北座601.602-107</p>
        <p class="title">电话</p>
        <p class="subtitle">0755-36878678</p>
    </div>
</template>
<script>
export default {
    data() {
        return {
            companyName:'港娱（深圳）文化传媒有限公司',
            companyAdddress:'深圳市福田区沙头街道天安社区泰然六路泰然苍松大厦六层北座601.602-107',
            companyIphone:'0755-36878678'
        }
    },
}
</script>
<style lang="scss">
    .US_warrper{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 1000;
        color: #fff;
        img{
            width: 1200px;
        }
        .title{
            font-weight: bold;
            font-size: 47px;
        }
        .subtitle{
            font-size: 25px;
        }
    }
      @media screen and (min-height: 900px){
        .US_warrper{
          zoom: 1;
        }
      }
      @media screen and (max-height: 900px) and (min-height: 820px){
        .US_warrper{
          zoom: .9;
        }
      }
      @media screen and (max-height: 820px){
        .US_warrper{
          zoom: .7;
        }
      }
      @media screen and (max-height: 720px){
        .US_warrper{
          zoom: .6;
        }
      }
      @media screen and (max-height: 540px){
        .US_warrper{
          zoom: .6;
        }
      }    
</style>