<template>
    <div>
        <div class="content">
            <div class="title">
                <div class="logo">
                    <img src="http://oss.wanwudata.com/hucinfo_logo.png" alt="">
                </div>
                <p class="subtitle">我们致力于提供高可用的校园服务</p>
            </div>
            <div class="iphone">
                <div class="iphone_warrper">
                    <img src="http://oss.wanwudata.com/hucinfo_iphone.png" alt="">
                </div>
            </div>
            <div class="core">
                <div class="qcode">
                    <div class="code_font">
                        <span class="iconfont icon-weixin"></span><span>遇见商大Pro</span>
                    </div>
                    <img src="http://oss.wanwudata.com/pro.f38dc835.png" alt="">
                </div>
                <div class="qcode">
                    <div class="code_font">
                        <span class="iconfont icon-weixin"></span><span>HucInfo</span>
                    </div>
                    <img src="http://oss.wanwudata.com/HucInfo.0fc155ff.jpg" alt="">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    created() {
        // this.isIPhoneX()
    },
    methods: {
        isIPhoneX(fn) {
            var u = navigator.userAgent;
            var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if (isIOS) {
                alert("ios")
            }
        },
    },
}
</script>
<style lang="scss" scoped>
    .content{
            position: absolute;
            top: 46%;
            left: 50%;
            transform: translate(-50%,-50%);
            display: flex;
            flex-direction: column;
            align-items: center;
            zoom: 1;
            .title{
                width: 100%;
                .logo{
                    width: 100%;    
                    display: flex;
                    justify-content: center;
                    img{
                        width: 310px;
                        height: auto;
                    }
                }
                .subtitle{
                    white-space:nowrap;
                    width: 100%;
                    color: #fff;
                    font-size: 20px;
                    margin-top: 0;
                }
            }

            .iphone{
                position: relative;
                video{
                    width: 150px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    z-index: -1;
                }
                .iphone_warrper{
                    width: 150px;
                    height: 300px;
                    background-image: url('http://oss.wanwudata.com/bg_iphone.a1839216.png');
                    background-repeat: no-repeat;
                    background-size: contain;
                    overflow: hidden;
                    position: relative;
                }
                img{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    height: 94%;
                    z-index: -1;
                }
            }
            .core{
                display: flex;
                box-sizing: border-box;
                color: #fff;
                width: 100%;
                white-space:nowrap; 
                justify-content: center;
                .subtitle{
                    font-size: 14px;
                    font-weight: bold;
                    margin: 10px 0;
                    letter-spacing: 2px;
                }
                
                .qcode{
                    width: auto;
                    margin-top: 20px;
                    padding: 10px;
                    box-sizing: border-box;
                    background-color: #fff;
                    border-radius: 20px;
                    .code_font{
                        color: #000;
                        font-size: 12px;
                        display: flex;
                        
                        justify-content: center;
                        align-items: center;
                        .iconfont{
                            margin-right: 3px;
                            font-size: 20px;
                        }
                    }
                    img{
                        height: 100px;
                        border-radius: 20px;
                    }

                    &:nth-child(1){
                        margin-right: 30px;
                    }
                }

                .c_logo{
                    width: 115px;
                    img{
                        width: 100%;

                    }
                }
            }
        }
        @media screen and (min-width: 320px) and (max-width: 375px) {
            .content{
                zoom: 0.8;
            }
            header{
                zoom: 0.8;
            }
        }
        
        @media screen and (min-width: 375px) and (max-width: 414px){
            .content{
                zoom: 0.9;
            }
            header{
                zoom: 0.9;
            }
        }
</style>