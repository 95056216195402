<template>
    <div class="content">
        <p class="title">名称</p>
        <!-- <p class="subtitle">港娱（深圳）文化传媒有限公司</p> -->
        <p class="subtitle">{{companyName}}</p>
        <p class="title">地址</p>
        <!-- <p class="subtitle">深圳市福田区沙头街道天安社区泰然六路泰然苍松大厦六层北座601.602-107</p> -->
        <p class="subtitle">{{companyAddress}}</p>
        <p class="title">电话</p>
        <!-- <p class="subtitle">0755-36878678</p>         -->
        <p class="subtitle">{{companyAddress}}</p>        
    </div>
</template>
<script>
export default {
    data() {
        return {
            companyName:'港娱（深圳）文化传媒有限公司',
            companyAddress:'深圳市福田区沙头街道天安社区泰然六路泰然苍松大厦六层北座601.602-107',
            companyIPhome:'0755-36878678'
        }
    },
}
</script>
<style lang="scss" scoped>
    .content{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        display: flex;
        flex-direction: column;
        color: #fff;
        zoom: 1;
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        .title{
            font-size: 26px;
            font-weight: bold;
        }
        .subtitle{
            width: 100%;
            font-size: 14px;
        }
    }
</style>