<template>
  <div class="container">
    <div class="bg">
      <img src="http://oss.wanwudata.com/hucinfo_bg_iphone.jpg" alt="" />
    </div>
    <keep-alive>
      <router-view :key="$route.path"></router-view>
    </keep-alive>

    <div class="footer">
      <a href="https://beian.miit.gov.cn">
        鲁ICP备17036887号 Copyright 2016-2021 遇见商大Pro
      </a>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      codeyzm: "",
			biaozhi: "",
			bodyHeight:'100vh'
    };
  },
  created() {
		this.getyzm();
  },
  methods: {
    getyzm() {
      this.$axios.get("/yzm").then((res) => {
        this.codeyzm = res.data.img;
        this.biaozhi = res.data.biaozhi;
        console.log(res.data);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  position: relative;
  width: 100%;
	height: 100vh;
	margin: 0 auto;
  .bg {
    width: 100%;
		height: 100%;
    overflow: hidden;
    position: absolute;
    img {
      width: 100%;
      height: 100%;
    }
  }
  header {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    color: #fff;
    padding: 10px;
    box-sizing: border-box;
    .logo {
      width: 140px;
      margin-left: 20px;
      height: auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .nav {
      ul {
        display: flex;
        font-weight: bold;

        li {
          margin-right: 20px;
          cursor: pointer;
        }
      }
    }
  }
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    zoom: 1;
    .iphone {
      position: relative;
      margin-right: 30px;
      video {
        width: 150px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
      }
      .iphone_warrper {
        width: 150px;
        height: 300px;
        background-image: url("../assets/bg_iphone.png");
        background-repeat: no-repeat;
        background-size: contain;
        overflow: hidden;
        position: relative;
      }
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 148;
        height: 94%;
        z-index: -1;
      }
    }
    .core {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      box-sizing: border-box;
      color: #fff;
      width: 100%;
      white-space: nowrap;
      align-self: center;

      .subtitle {
        font-size: 14px;
        font-weight: bold;
        margin: 10px 0;
        letter-spacing: 2px;
      }

      .qcode {
        width: auto;
        margin-top: 20px;
        padding: 10px;
        box-sizing: border-box;
        background-color: #fff;
        border-radius: 20px;
        .code_font {
          color: #000;
          font-size: 12px;
          display: flex;

          justify-content: center;
          align-items: center;
          .iconfont {
            margin-right: 3px;
            font-size: 20px;
          }
        }
        img {
          height: 100px;
          border-radius: 20px;
        }
      }

      .c_logo {
        width: 115px;
        img {
          width: 100%;
        }
      }
    }
  }
  @media screen and (min-width: 320px) and (max-width: 375px) {
    .content {
      zoom: 0.8;
    }
    header {
      zoom: 0.8;
    }
  }

  @media screen and (min-width: 375px) and (max-width: 414px) {
    .content {
      zoom: 1;
    }
    header {
      zoom: 1;
    }
  }
  .footer {
    position: fixed;
    bottom: 2%;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    zoom: 0.5;
    display: flex;
    flex-direction: column;
    a {
      font-size: 10px;
      white-space: nowrap;
      text-align: center;
    }
  }
}
</style>