<template>
    <div class="container">
        <div class="content">
            <div class="logo">
                <img src="http://oss.wanwudata.com/hucinfo_logo.png" alt="">
            </div>
            <p class="subtitle">我们致力于提供高可用的校园服务</p>
            <div class="iphone_warrper">
                <img src="http://oss.wanwudata.com/hucinfo_iphone.png" alt="">
            </div>
            <div class="core">
                <div class="qcode">
                    <div class="code_font">
                        <span class="iconfont icon-weixin"></span><span>遇见商大Pro</span>
                    </div>
                    <img src="http://oss.wanwudata.com/pro.f38dc835.png" alt="">
                </div>
                <div class="qcode">
                    <div class="code_font">
                        <span class="iconfont icon-weixin"></span><span>HucInfo</span>
                    </div>
                    <img src="http://oss.wanwudata.com/HucInfo.0fc155ff.jpg" alt="">
                </div>
            </div>
        </div>
        <div class="footer">
            <a href="https://beian.miit.gov.cn">鲁ICP备17036887号 Copyright 2016-2021 遇见商大Pro</a>
        </div>
    </div>
</template>
<script>
export default {}
</script>
<style lang="scss" scoped>
    .container{
        width: 100%;
        height: 100vh;
        background-image: url("http://oss.wanwudata.com/hucinfo_bg_iphone.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        .content{
            position: absolute;
            top: 45%;
            left: 50%;
            transform: translate(-50%,-50%);
            display: flex;
            flex-direction: column;
            align-items: center;
            .logo{
                width: 100%;
                margin-bottom: 10px;
                img{
                    width: 300px;
                }
            }
            .subtitle{
                white-space: nowrap;
                width: 100%;
                color: #fff;
                font-size: 20px;
                margin-top: 0;
                // margin-bottom: 20px;
            }
            .iphone_warrper{
                width: 150px;
                height: 300px;
                background-image: url("http://oss.wanwudata.com/bg_iphone.a1839216.png");
                background-repeat: no-repeat;
                background-size: contain;
                overflow: hidden;
                position: relative;
                margin-top: 10px;
                img{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    z-index: -1;
                    height: 95%;
                }
            }
            .core{
                display: flex;
                box-sizing: border-box;
                justify-content: center;
                width: 100%;
                white-space: nowrap;
                margin-top: 20px;
                .qcode{
                    margin-top: 20px;
                    padding: 10px;
                    box-sizing: border-box;
                    background-color: #fff;
                    border-radius: 20px;
                    .code_font{
                        color: #000;
                        font-size: 12px;
                        display: flex;
                        justify-content: center;
                        align-items: center;                                            
                        .iconfont{
                            margin-right: 3px;
                            font-size: 20px;
                        }
                    }
                    img{
                        height: 100px;
                    }

                    &:nth-child(1){
                        margin-right: 40px;
                    }
                }
            }
        }
        @media screen and (min-width: 320px) and (max-width: 375px) {
            .content{
                zoom: 0.8;
            }
            header{
                zoom: 0.8;
            }
        }
        .footer{
            position: fixed;
            bottom: 2%;
            left: 50%;
            transform: translateX(-50%);
            white-space: nowrap;
            a{
                color: #fff;
                font-size: 10px;
            }
        }
    }
</style>