import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios'
import './style.scss'
import 'lib-flexible'
import './common/iconfont/iconfont.css'

axios.defaults.baseURL = 'http://127.0.0.1:3005/'
Vue.config.productionTip = false;
Vue.prototype.$axios = axios

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
