<template>
    <div class="iphone">
          <div class="i_video">
            <video src="http://oss.wanwudata.com/hucinfo_phone.mp4" webkit-playsinline="true" autoplay loop muted></video>
            <div class="ipone_warrper"></div>
          </div>
          <div class="text">
            <div class="title">
              <img src="http://oss.wanwudata.com/hucinfo_logo.png" alt="">
            </div>
            <div class="subtitles">我们致力于提供高可用的校园服务</div>
            <div class="qrcodes">
              <div class="ios">
                <span class="iconfont icon-weixin"></span>
                <span class="version">遇见商大Pro</span>
              </div>
              <div class="android">
                <span class="iconfont icon-weixin"></span>
                <span class="version">HucInfo</span>
              </div>
            </div>
          </div>
        </div>
</template>
<script>
export default {
    
}
</script>
<style lang="scss" scoped>
    @media screen and (min-height: 900px){
        .iphone{
          zoom: 1;
        }
      }
      @media screen and (max-height: 900px) and (min-height: 820px){
        .iphone{
          zoom: .9;
        }
      }
      @media screen and (max-height: 820px){
        .iphone{
          zoom: .7;
        }
      }
      @media screen and (max-height: 720px){
        .iphone{
          zoom: .6;
        }
      }
      @media screen and (max-height: 540px){
        .iphone{
          zoom: .5;
        }
      }
      .iphone{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-60%,-60%);
        display:flex;
        justify-content: center;
        align-self: center;
        z-index: 1000;
        .i_video{
          margin-right: 60px;
          position: relative;
          video{
            width: 260px;
            height: 564px;
            position: absolute;
            left: 50%;
            top: 50%;
            z-index: -1;
            transform: translate(-50%,-50%);
          }
          .ipone_warrper{
            width: 300px;
            height: 600px;
            background-image: url('http://oss.wanwudata.com/bg_iphone.a1839216.png');      
            background-repeat: no-repeat;
            background-size: contain;
          }
        }
        .text{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          box-sizing: border-box;
          align-self: center;
          
          .title{
            width: 500px;
            color: #fff;
            font-size: 40px;
            img{
              width: 100%;
              height: 100%;
            }
          }
          .subtitles{
            margin: 13px 0 0;
            color: #fff;
            font-size: 48px;
            font-weight: 500;
          }
          .qrcodes{
            display: flex;
            margin-top: 46px;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .ios{
              background-color: #fff;
              width: 150px;
              height: 40px;
              margin-right: 15px;
              border-radius: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              .iconfont{
                font-size: 24px;
                margin-right: 5px;
              }
              .version{
                font-size: 17px;
              }
              &:hover {
                  &::after{
                    content: " ";
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 150px;
                    height: 165px;
                    background-color: #fff;
                    z-index: -1;
                    border-bottom-right-radius: 20px;
                    border-bottom-left-radius: 20px;
                }
                &::before{
                  content: " ";
                  position: absolute;
                  top: 100%;
                  left: 50%;
                  transform: translateX(-50%);
                  width: 134px;
                  height: 134px;
                  background-image:url('http://oss.wanwudata.com/pro.f38dc835.png');
                  background-size: contain;
                  background-repeat: no-repeat;
                }
              }
            }
            .android{
              background-color: #fff;
              width: 150px;
              height: 40px;
              border-radius: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              .iconfont{
                font-size: 24px;
                margin-right: 5px;
              }
              .version{
                font-size: 15px;
              }
              &:hover {
                  &::after{
                    content: " ";
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 150px;
                    height: 165px;
                    background-color: #fff;
                    z-index: -1;
                    border-bottom-right-radius: 20px;
                    border-bottom-left-radius: 20px;
                 }
                 &::before{
                   width: 134px;
                   height: 134px;
                   position: absolute;
                   content: " ";
                   top: 100%;
                   left: 50%;
                   transform: translateX(-50%);
                   background-image:url('http://oss.wanwudata.com/HucInfo.0fc155ff.jpg');
                   background-repeat: no-repeat;
                   background-size: contain;
                 }
              }
            }
          }
        }
      }
</style>