<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  created() {
    const isIOS = this.isIPhoneX()
    var ua = navigator.userAgent.toLowerCase();
    var isWeixin = ua.indexOf('micromessenger') != -1;
    // 判断pc端还是移动端
    if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))){
      window.localStorage.setItem("mode",'phone')
      if(isIOS){
        // 判断是否是ios设备
        if(isWeixin){
          // 判断是否用微信打开
            this.$router.replace('iphone_mini')
          }else{
            // 跳转至普通移动端界面 push会添加历史记录
            this.$router.push('Home_mini')
          }
        }else{
          // 如果不是iOS设备就跳转至普通的移动端界面
          this.$router.push('Home_mini')
        }
    }else{
      window.localStorage.setItem("mode",'pc')
      // PC端
      this.$router.push('/')
    }
  },
  methods: {
    isIPhoneX(fn) {
      var u = navigator.userAgent;
      var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      return isIOS
    },
  },
}
</script>

<style lang="scss">
body{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
#app {

}

</style>
