<template>
  <div class="container">
    <div class="content">
      <header>
        <!-- <div class="logo">
          <img src="http://oss.wanwudata.com/hucinfo_logo.png" alt="">
        </div> -->
        <!-- <ul class="header_ui">
          <li>
            <router-link to="/index">首页</router-link>
          </li>
          <li>
            <router-link to="Us" class="US">关于我们</router-link>
          </li>
        </ul> -->
      </header>
    </div>

  <keep-alive>
    <router-view :key="$route.path"></router-view>
  </keep-alive>

    <div class="footer">
      <a href="https://beian.miit.gov.cn">
        鲁ICP备17036887号 Copyright 2016-2021 遇见商大Pro
      </a>
    </div>

    <div class="wrapper">
      <div class="bg">
        <video src="http://oss.wanwudata.com/hucinfo_background.mp4" class="bg_video" autoplay loop :muted="muted" style="object-fit: fill;"></video>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data() {
    return {
      muted:true
    }
  },
  props: {
    msg: String,
  },
  methods: {
    US(){
      console.log('aaaa')
    }
  },
};
</script>

<style scoped lang="scss">
.container{
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  position: relative;


    header{
      width: 100%;
      position: fixed;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 100;
      font-size: 28px;
      height: 106px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #fff;
      box-sizing: border-box;
      overflow: hidden;

      .logo{
        margin-left: 91px;
        width: 200px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .US{
        z-index: 1000;
      }
      .header_ui{
        display: flex;
        margin-right: 91px;
        font-size: 16px;
        font-weight: bold;
        li{
          margin-left: 58px;
          z-index: 1000;
          position: relative;
          cursor: pointer;
        }
      }
    }

    @media screen and (max-width: 1305px) and (min-width: 1250px){
      header{
        zoom: .9;
      }
    }
    @media screen and (max-width: 1150px) and (min-width: 1101px){
      header{
        zoom: .8;
      }
    }
    @media screen and (max-width: 1046px){
      header{
       zoom: .7;
    }
  }

  .middle{
    position: fixed;

  }

  video{
    object-fit: contain;
  }

  .wrapper{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .bg{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
        .bg_video{
          width: 100%;
          height: 100%;
          z-index: -11111111111;
        }
        .bg_video video{
          width: 100%;
          height: 100%;
        }
      }
    }
  .footer{
    position: fixed;
    bottom: 2%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
    white-space:nowrap;
    a{
      color: #999;
    }
  }
}

</style>
