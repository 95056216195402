import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Index from '../components/Index.vue'
import Us from '../components/Us.vue'
import HomeMini from '../views/Home_mini.vue'
import IndexMini from '../components/Index_mini.vue'
import UsMini from '../components/Us_mini.vue'
import Iphone from '../views/iphone_mini.vue'
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component:Home,
    redirect:'/Index',
    children:[
      {
        path:'/Index',
        component:Index
      },
      {
        path:'/Us',
        component:Us
      }
    ]
  },
  {
    path:'/Home_mini',
    name:'Home_mini',
    component:HomeMini,
    redirect:'/Index_mini',
    children:[
      {
        path:'/Index_mini',
        component:IndexMini
      },
      {
        path:'/Us_mini',
        component:UsMini
      }
    ]
  },
  {
    path:'/iphone_mini',
    component:Iphone
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
